import React, { Component } from 'react'
export default class NosServices extends Component {
    render() {
        return (
            <div className="nos-services">
                
                <div className="column-services" id="achat"> test         
<div className=" content-bg content">test</div></div>
                <div className="column-services" id="vente">test</div>
                <div className="column-services" id="dépôt">test</div>
                <div className="column-services" id="commande">test</div>

            </div>
        )
    }
}
